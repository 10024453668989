{
  "FUSIONAUTH_AUTHORITY": "https://auth.sitemate.com",
  "NODE_ENV": "development",
  "GLOBAL_API_URL": "https://api-global-dev.hseqai.com/",
  "SITEMATE_API_URL": "https://api-dev.hseqai.com/",
  "FUSIONAUTH_FLOWSITE_APPLICATION_ID": "7cdc6c5c-8d25-4eb8-ba41-60a6ed6586f4",
  "FUSIONAUTH_DASHPIVOT_APPLICATION_ID": "250a2b0d-3fa5-43c2-9b04-69244d418d6b",
  "DASHPIVOT_CALLBACK_PATH": "/oauth-callback/dashpivot",
  "XERO_CLIENT_PKCE": "945FE0BB63E54FCEB0BDAA8EBB5A974D",
  "XERO_CALLBACK_PATH": "/oauth-callback/xero",
  "QUICKBOOKS_CLIENT_ID": "ABo501AhhYUTvvff4DefklS5GcRmqw7lFxPq3EL4RJvWhZ1I4h",
  "QUICKBOOKS_CALLBACK_PATH": "/oauth-callback/quickbooks",
  "MICROSOFT_CLIENT_ID": "e1b77bf4-41b8-4285-a516-a3d6ba48c26f",
  "MICROSOFT_CALLBACK_PATH": "/oauth-callback/microsoft"
}
